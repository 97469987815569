import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import VideoComponent from "../VideoComponent";

const CardSection = ({
  heading,
  imgSrc,
  imgAlt,
  videoSrc,
  imageWidth,
  imageHeight,
}) => {
  return (
    <div className="flex flex-col justify-end max-w-2xl overflow-hidden bg-white shadow-lg lg:max-w-sm h-80 sm:max-w-xs rounded-2xl">
      {(videoSrc || imgSrc) && (
        <section className="flex-1 h-60 lg:flex-none">
          {videoSrc ? (
            <VideoComponent
              loop
              autoPlay
              muted
              className="object-cover w-full lg:h-full h-60"
            >
              <source src={videoSrc} type="video/mp4" />
            </VideoComponent>
          ) : (
            <GatsbyImage
              image={imgSrc}
              alt={imgAlt || "Card Image"}
              className="object-cover w-full lg:h-full h-60"
              loading="lazy"
            />
          )}
        </section>
      )}

      <div className="flex flex-col justify-center flex-1 p-5 space-y-3 text-left lg:p-6 sm:space-y-5 lg:flex-none h-22">
        <h4 className="text-xl font-semibold text-center sm:text-2xl text-purple-1000">
          {heading}
        </h4>
      </div>
    </div>
  );
};

export default CardSection;
