import React, { useState } from "react";
import VideoComponent from "../VideoComponent";
import posterVideo from "../../assets/videoPoster.png";
const Video = ({ src }) => {
  return (
    // video Component
    <VideoComponent
      poster={posterVideo}
      data-aos="fade-up"
      data-aos-delay="150"
      key={src}
      className="h-full z-10"
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};

const AnnotationTypes = ({ annotation_types }) => {
  const [activeAnnotation, setActiveAnnotation] = useState(0);
  return (
    <article className="py-10 bg-white shadow-card lg:py-24 sm:py-16 md:px-0 sm:px-12">
      <section className="flex flex-col px-5 mx-auto lg:gap-x-28 sm:gap-y-10 gap-y-6 md:flex-row sm:px-8 max-w-7xl">
        <div className="flex-1 w-full my-auto space-y-6 text-left lg:max-w-lg lg:space-y-12 sm:space-y-10 lg:my-5">
          <div>
            <h4 className="text-purple-1000 text-xl sm:text-2.5xl font-semibold tracking-tightest">
              {annotation_types?.primary?.section_heading?.text}
            </h4>

            <p className="mt-5 text-base text-gray-600 tracking-tighter_1 lg:pr-20">
              {annotation_types?.primary?.section_description?.text}
            </p>
          </div>

          <div className="border-t bg-gray-1400" />

          <div className="flex flex-wrap max-w-md gap-1.5 md:justify-start">
            {annotation_types?.items?.map(({ label_icon, label }, index) => {
              return (
                <div
                  className={`flex items-center px-2.5 py-1 cursor-pointer ${
                    activeAnnotation !== index
                      ? "bg-gray-1600 text-purple-1000"
                      : " bg-purple-1500 text-white"
                  } rounded-md`}
                  key={index}
                  onClick={() => setActiveAnnotation(index)}
                >
                  <img
                    src={label_icon?.url}
                    width={0}
                    height={0}
                    alt={label_icon?.alt || "icon"}
                    loading="lazy"
                    style={{
                      filter:
                        activeAnnotation !== index
                          ? "invert(0)"
                          : "invert(100%)",
                    }}
                    className={`w-5 h-5 mr-2.5`}
                  />
                  <p className="text-xs sm:text-base">{label?.text}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex-1">
          {annotation_types?.items[activeAnnotation]?.label_slide_video?.url ? (
            <div className="flex justify-center w-full h-auto lg:max-w-2xl lg:justify-end sm:h-128 relative  duration-[1s] ease-in">
              <Video
                src={
                  annotation_types?.items[activeAnnotation]?.label_slide_video
                    ?.url
                }
              />
            </div>
          ) : (
            <img
              src={
                annotation_types?.items[activeAnnotation]?.label_slide_image
                  ?.url
              }
              width={
                annotation_types?.items[activeAnnotation]?.label_slide_image
                  ?.dimensions?.width || 0
              }
              height={
                annotation_types?.items[activeAnnotation]?.label_slide_image
                  ?.dimensions?.height || 0
              }
              alt={
                annotation_types?.items[activeAnnotation]?.label_slide_image
                  ?.alt || "supporting annotation"
              }
              loading="lazy"
              className="w-full h-auto max-w-2xl rounded-2xl"
            />
          )}
        </div>
      </section>
    </article>
  );
};

export default AnnotationTypes;
