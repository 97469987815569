import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import VideoComponent from "../VideoComponent";

const RowsSection = ({
  heading,
  description,
  btnText,
  btnLink,
  imgSrc,
  videoSrc,
  imgAlt,
  isReverse,
  imageContainer,
  customClass,
}) => {
  return (
    <section
      className={`${customClass} mt-12 mx-auto flex lg:px-0 sm:px-2 px-0 lg:py-0 sm:py-4 py-3 ${
        isReverse ? "lg:flex-row-reverse" : "lg:flex-row"
      } flex-col-reverse justify-between bg-inherit lg:gap-x-32`}
    >
      <div className="flex flex-col self-start flex-1 w-full max-w-lg pb-5 mb-0 space-y-5 text-left lg:self-center lg:mb-0 lg:max-w-xl sm:max-w-full md:mb-0 lg:py-0 sm:pt-14 pt-9">
        <h4 className="text-purple-1000 text-xl sm:text-2.5xl font-semibold leading-tight tracking-tightest">
          {heading}
        </h4>

        <p className="text-base text-purple-1000 tracking-tighter_1">
          {description}
        </p>

        <Link
          to={btnLink}
          className="flex items-center text-base font-bold text-purple-500 w-max"
        >
          <span>{btnText}</span>
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
              fill="#5658DD"
            />
          </svg>
        </Link>
      </div>

      <div className={`rounded-3xl 2xl:p-10 flex-1 flex ${imageContainer} gatby-custom-image`}>
        {videoSrc ? (
          <VideoComponent
            loop
            autoPlay
            muted
            className="w-full h-auto mx-auto rounded-2xl"
          >
            <source src={videoSrc} type="video/mp4" />
          </VideoComponent>
        ) : (
          <GatsbyImage
            image={imgSrc}
            alt={imgAlt || "Section image"}
            className="w-full h-auto max-w-md max-h-full mx-auto rounded-2xl 2xl:p-10 lg:max-w-none"
            loading="lazy"
          />
        )}
      </div>
    </section>
  );
};

export default RowsSection;
