import React from "react";
import { Link } from "gatsby";
import commas from "../../assets/white-commas.svg";
import "../../styles/page/industries_page.scss";

const TestimonialCardSection = ({
  heading,
  description,
  icon,
  featureText,
  learnMoreLink,
  isLarger = false,
}) => {
  return (
    <div className="flex flex-col justify-between w-full max-w-full p-5 mx-auto overflow-hidden bg-white lg:p-5 shadow-header md:px-8 lg:max-w-md rounded-2xl">
      <div>
        {icon?.url && (
          <div className="flex items-center justify-between py-5">
            <img
              src={icon?.url}
              width={icon?.dimensions?.width || 0}
              height={icon?.dimensions?.height || 0}
              alt={icon?.alt || "Testimonial Icons"}
              loading="lazy"
              className={`max-w-min ${
                icon?.dimensions?.height > 24 ? "pr-6" : ""
              } ${
                icon?.dimensions?.height <= 700 &&
                icon?.dimensions?.width >= 1500
                  ? "max-h-9"
                  : icon?.dimensions?.height <= 250 &&
                    icon?.dimensions?.width <= 450
                  ? "max-h-9"
                  : icon?.dimensions?.height <= 325 &&
                    icon?.dimensions?.width == 1000
                  ? "max-h-9"
                  : "max-h-6"
              }`}
            />
            <img
              src={commas}
              width={0}
              height={0}
              alt={"Commas"}
              loading="lazy"
              className="w-20 h-auto"
            />
          </div>
        )}
        {featureText ? (
          <div className="flex gap-x-2">
            <div className="p-1 text-xl text-white font-semibold h-9 feature_text rounded-sm max-w-[33px] min-w-[60px]">
              {featureText}
            </div>
            <h4
              className={`text-[#434343] text-xl leading-tight tracking-tighter_1 font-semibold text-left mt-1.5 ${
                isLarger ? "lg:min-h-[85px]" : "lg:min-h-[56px]"
              }`}
            >
              {heading}
            </h4>
          </div>
        ) : (
          <h4
            className={`text-[#434343] text-xl font-semibold text-left tracking-tighter_1 ${
              isLarger ? "lg:min-h-[85px]" : "lg:min-h-[56px]"
            }`}
          >
            {heading}
          </h4>
        )}
        <p className="py-5 text-base font-normal text-left text-gray-600 lg:py-6 sm:py-4">
          {description}
        </p>
      </div>

      <div className="flex-end">
        <Link
          to={learnMoreLink}
          className="flex items-center text-base font-bold text-purple-500 w-max"
        >
          <a src={learnMoreLink}>Learn more</a>
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
              fill="#5658DD"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default TestimonialCardSection;
