import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";
import VideoComponent from "../VideoComponent";

const buttonStyles = {
  Github: "bg-[#4C4C50]",
  "Join our Discord": "!bg-white !text-purple-500 flex-row-reverse",
};

const HeroSection = ({
  location,
  heading,
  description,
  videoSRC,
  imageSRC,
  pageName,
  buttonList,
  showForm = true,
  marginTop = "mt-8",
  videoMarginTop = "",
  trusted_brands_list,
  videoPoster
}) => {
  return (
    <>
      <section className="w-full max-w-3xl mx-auto space-y-6">
        <h1
          dangerouslySetInnerHTML={{ __html: heading }}
          className="industries_h1_gradient text-3.25xl sm:leading-tight tracking-tightest sm:text-4xl font-semibold text-purple-1000"
        />

        <p className="max-w-2xl mx-auto text-base text-purple-1000 sm:text-xl">
          {description}
        </p>

        {showForm && (
          <section className="flex justify-center">
            <NewHomeLeadFormWithAPIForm
              pageName={pageName}
              location={location}
              btnText="Get started"
              isCustomBtnBg={true}
              btnColor="gradient_btn border-0 h-[50px]"
            />
          </section>
        )}

        {buttonList && (
          <section className="flex flex-wrap justify-center gap-3 items-cente sm:flex-nowrap">
            {buttonList.map((button, index) => {
              const { button_icon, button_text, button_link } = button?.primary;
              return (
                <a
                  href={button_link?.url || ""}
                  key={index}
                  rel="noopener noreferrer"
                  target="__blank"
                  className={`flex justify-center flex-shrink-0  shadow-card gap-2 bg-purple-500 py-3 px-10 rounded-full text-white text-sm 2xl:text-base font-semibold ${
                    buttonStyles[button_text.text]
                  }`}
                >
                  {button_icon?.url && (
                    <img
                      src={button_icon?.url}
                      alt={button_text?.text || "hero Image"}
                      width={0}
                      height={0}
                      className="w-5 h-auto"
                    />
                  )}
                  {button_text.text}
                </a>
              );
            })}
          </section>
        )}
      </section>

        {videoSRC ? (
          <VideoComponent
            loop
            autoPlay
            muted
            poster={videoPoster}
            className={`mx-auto h-auto max-h-[600px] rounded-lg shadow-card ${videoMarginTop}`}
          >
            <source src={videoSRC} type="video/mp4" />
          </VideoComponent>
        ) : (
          <>
            {imageSRC ? (
              <GatsbyImage
                image={getImage(imageSRC)}
                alt="stats"
                className={`${marginTop} max-w-4xl mx-auto w-full max-h-[600px] rounded-lg shadow-card"`}
                loading="eager"
              />
            ) : (
              ""
            )}
          </>
        )}

      <section className="mx-auto space-y-4 pt-7 section_container lg:pt-12 lg:pb-5">
        <section className="img_grayscale_opacity">
          <p className="text-center uppercase sm:text-sm text-2xs text-gray-1300 tracking-[0.07em]">
            {trusted_brands_list?.primary?.section_heading?.text}
          </p>
        </section>
        <section className="flex flex-wrap items-center justify-between max-w-3xl gap-2 mx-auto sm:flex-nowrap sm:gap-4 lg:gap-8">
          {trusted_brands_list?.items?.map((item, index) => {
            const image = getImage(item?.brand_logo);
            return (
              <div
                key={`brand_${index}`}
                className="flex justify-center col-span-1 img_grayscale_opacity"
              >
                <GatsbyImage
                  image={image}
                  alt={"Logos "}
                  className={`h-auto max-w-[80px] img_grayscale object-fill`}
                  loading="lazy"
                />
              </div>
            );
          })}
        </section>
      </section>
    </>
  );
};

export default HeroSection;
